import React from "react";
import { ReactSVG } from "react-svg";

// region Libraries
import { Divider } from "@material-ui/core";
import _ from "lodash";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, VehicleAndTravelDetailsMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { WaterAddition as WaterAdditionInterface } from "@shared/interfaces/water-addition.interface";
// endregion Interfaces
// region Assets
import IconWater from "@assets/icons/water.svg";
// endregion Assets
// region Utils
import utils from "../../../utils/useful-functions";
// endregion Utils
// region Styles
import { Container } from "./styles";
// endregion Styles

type WaterAdditionItemProps = { waterAdditions: WaterAdditionInterface[]; }
const WaterAdditionModalList: React.FC<WaterAdditionItemProps> = ({ waterAdditions }) => {

  const { t } = useTranslation();

  return (
    <Container>
      {!_.isEmpty(waterAdditions) && waterAdditions.map((waterAddition) => (
        <div key={waterAddition.id_water_addition} id={waterAddition.id_water_addition}>
          <div className="waterAddition">
            <div
              className="boxHeaderItemsEvent"
            >
              <div className="content">
                <div className="boxHeaderItemsIcon">
                  <ReactSVG src={IconWater} />
                </div>
                <div className="boxHeaderItemsDescription">
                  <div className="heading typeAndDuration">
                    <div
                      className="type"
                      style={{ color: "#000" }}
                    >
                      {t(GlobalMessages.quantity)}
                    </div>
                    <div className="duration">
                      {waterAddition?.water_added.toString().replace(".", ",")}L
                    </div>
                  </div>
                  {waterAddition?.start_date && (
                    <div className="heading startDate">
                      <div>{t(GlobalMessages.time)}:</div>
                      <div>{utils.formatDateIfHave(waterAddition.start_date, "time")}</div>
                    </div>
                  )}
                  {waterAddition?.is_post_discharge !== undefined && (
                    <div className="heading moment">
                      <div>{t(GlobalMessages.moment)}:</div>
                      <div>
                        {
                          waterAddition?.is_post_discharge
                            ? t(VehicleAndTravelDetailsMessages.waterAdditionPostDischarge)
                            : t(VehicleAndTravelDetailsMessages.waterAdditionPreDischarge)
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
          <Divider />
        </div>
      ))}
    </Container>
  );
};

export default WaterAdditionModalList;
