export enum FilterApplicationTypesID {
  TRAVEL_HISTORY = "f9f3e0e0-3d9d-4d4b-8b5c-6b8f2a6a3f3e",
  ALERT_HISTORY = "a1e2d3b3-9e9d-4e9c-a89e-87c44d179228",
  VEHICLE_HISTORY = "4b76ca40-8c29-4617-837d-a4924d265570",
  COORDINATE_TRAVEL_HISTORY = "05b8e88d-18e4-488c-a9d9-e64cdb8aa144",
  PUMP_PROGRAMMING = "9abfba72-3241-4565-bf7e-1e3396fe1f63",
  PUMP_PROGRAMMING_HISTORY = "deb7163f-b3c9-41ed-a7d4-09b05f00ee20"
}

export function getFilterApplicationTypeName(id: string | undefined): string | undefined {
  if (!id) return undefined;

  for (const [key, value] of Object.entries(FilterApplicationTypesID)) {
    if (value === id) {
      return key;
    }
  }

  return undefined;
}
