export enum HardwareModels {
  "ST 300HD" = "ST 300HD",
  "ST 310U" = "ST 310U",
  "ST 310UC2" = "ST 310UC2",
  "ST 310U EVT" = "ST 310U EVT",
  "ST 340UR" = "ST 340UR",
  "ST 4315U EVT" = "ST 4315U EVT",
  "ST 4315U" = "ST 4315U",
  "ST 4305" = "ST 4305",
  "ST 8310U EVT" = "ST 8310U EVT",
  "ST 8310U" = "ST 8310U",
  "02684" = "2684",
  "0721" = "0721",
  "RAS_1" = "RAS_1",
  "GV350MG" = "GV350MG",
  "ST 380" = "ST 380",
  "GV350CEU" = "GV350CEU"
}

export function isHardwareEVTModel(model: HardwareModels): boolean {
  return model.endsWith("EVT");
}
