import { VehiclesStatesMessages, TranslationVehiclesStatesMessages } from "../interfaces";

export const messages: TranslationVehiclesStatesMessages = {
  [VehiclesStatesMessages.atThePlant]: "En la planta",
  [VehiclesStatesMessages.onTheWay]: "En camino",
  [VehiclesStatesMessages.atTheConstructionSite]: "En la obra",
  [VehiclesStatesMessages.unloading]: "Descargando",
  [VehiclesStatesMessages.returning]: "Regresando",
  [VehiclesStatesMessages.atDestination]: "En el destino",
  [VehiclesStatesMessages.inTrustedLocation]: "En local confiable",
  [VehiclesStatesMessages.pumping]: "Bombeando",
  [VehiclesStatesMessages.idle]: "Ociosa"
};
