import React from "react";

// region imports - External libraries
import _ from "lodash";
// endregion imports - External libraries
// region imports - Atoms
import AccordionSummaryChips from "@atoms/AccordionSummaryChips";
// endregion imports - Atoms
// region imports - Molecules
import KanbanAccordionCard from "@molecules/KanbanAccordionCard";
// endregion imports - Molecules
// region imports - Organisms
import { IDateRangeValues, MaxRangeType } from "@organisms/GenericQueryFilter";
// endregion imports - Organisms
// region imports - Languages
import { FilterMessages, YupMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
// endregion imports - Languages
// region imports - Utils
import DateRange from "@shared/utils/date-range";
import utils from "@utils/useful-functions";
// endregion imports - Utils
// region imports - Libraries
import { TextField } from "@libraries/mui";
// endregion imports - Libraries
// region imports - Styles
import * as Styled from "./styles";
// endregion imports - Styles

// region Interfaces

interface IProps {
  label: string;
  onChangeDateRange?: (values: IDateRangeValues) => void;
  defaultValues: IDateRangeValues;
  maxRange?: MaxRangeType;
}

// endregion Interfaces

const KanbanAccordionCardFilterDateRange: React.FC<IProps> = ({
  label,
  onChangeDateRange,
  defaultValues,
  maxRange
}) => {

  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks
  // region Refs
  const lastDefaultValues = React.useRef<IDateRangeValues>(defaultValues);
  // endregion Refs
  // region States
  const [expandedCard, setExpandedCard] = React.useState<boolean>(false);

  const [currentStartDate, setCurrentStartDate] = React.useState<string>(defaultValues.startDate);
  const [currentEndDate, setCurrentEndDate] = React.useState<string>(defaultValues.endDate);

  const [startDateHelperMessage, setStartDateHelperMessage] = React.useState<string>("");
  const [endDateHelperMessage, setEndDateHelperMessage] = React.useState<string>("");
  // endregion States
  // region Functions
  /**
   * Function to handle the change of the accordion card (Expand or collapse)
   * @param _event The event
   * @param isExpanded If the card is expanded or not
   */
  const handleAccordionCardChange = async (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedCard(isExpanded);
  };

  /**
   * Function to handle the change of the date fields
   * @param event The event
   */
  const handleChangeDate = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!value) return;

    validateRange(name, value);

    let startDate: Date;
    let endDate: Date;
    let setCurrentDateField: React.Dispatch<React.SetStateAction<string>>;

    if (name === "startDate") {
      startDate = new Date(value);
      endDate = new Date(currentEndDate);
      setCurrentDateField = setCurrentStartDate;
    } else {
      startDate = new Date(currentStartDate);
      endDate = new Date(value);
      setCurrentDateField = setCurrentEndDate;
    }

    setCurrentDateField(value);

    const formattedStartDate = utils.convertDateToISOWithTimezone(startDate);
    const formattedEndDate = utils.convertDateToISOWithTimezone(endDate);

    if (onChangeDateRange) onChangeDateRange({ startDate: formattedStartDate, endDate: formattedEndDate });
  };

  /**
   * Validate the range of dates and set the yup message if necessary
   * @param name The name of the field
   * @param value The value of the field
   */
  const validateRange = (name: string, value: string) => {
    let startDate: string;
    let endDate: string;
    let setYupMessageField: React.Dispatch<React.SetStateAction<string>>;

    if (name === "startDate") {
      setYupMessageField = setStartDateHelperMessage;
      startDate = value;
      endDate = currentEndDate;
    } else {
      setYupMessageField = setEndDateHelperMessage;
      startDate = currentStartDate;
      endDate = value;
    }

    if (startDate > endDate) {
      setYupMessageField(t(YupMessages.finishDateGreaterThanStartDate));

      return;
    }

    if (maxRange) {
      const dateRange = new DateRange(startDate, endDate);

      let maxRangeInDays: number;
      let maxDateYupMessage: string;

      if (maxRange === "1D") {
        maxRangeInDays = 1;
        maxDateYupMessage = YupMessages.maxDateInterval24h;
      } else if (maxRange === "7D") {
        maxRangeInDays = 7;
        maxDateYupMessage = YupMessages.maxDateInterval7d;
      } else {
        maxRangeInDays = 30;
        maxDateYupMessage = YupMessages.maxDateInterval30d;
      }

      if (!dateRange.isValidRange("DAYS", maxRangeInDays)) {
        setYupMessageField(t(maxDateYupMessage));

        return;
      }
    }

    setStartDateHelperMessage("");
    setEndDateHelperMessage("");
  };

  // endregion Functions
  // region Effects
  // UseEffect to update the current date values when the default values change
  React.useEffect(() => {
    if (_.isEqual(defaultValues, lastDefaultValues.current)) return;

    setCurrentStartDate(defaultValues.startDate);
    setCurrentEndDate(defaultValues.endDate);
    lastDefaultValues.current = defaultValues;
  }, [defaultValues]);
  // endregion Effects

  return (
    <Styled.Container>
      <KanbanAccordionCard
        onChange={handleAccordionCardChange}
        showExpandIcon
        accordionSummary={(
          <AccordionSummaryChips
            label={label}
            chips={[]}
            onDeleteChip={() => undefined}
          />
        )}
        expanded={expandedCard}
      >
        <Styled.AccordionDetail>
          { expandedCard && (
            <>
              <TextField
                name="startDate"
                label={t(FilterMessages.optionStartDate)}
                fullWidth
                type="datetime-local"
                value={currentStartDate.slice(0, 16)}
                onChange={(event) => handleChangeDate(event)}
                InputLabelProps={{ shrink: true }}
                error={!_.isEmpty(startDateHelperMessage)}
                helperText={startDateHelperMessage}
              />
              <TextField
                name="endDate"
                label={t(FilterMessages.optionEndDate)}
                fullWidth
                type="datetime-local"
                onChange={(event) => handleChangeDate(event)}
                value={currentEndDate.slice(0, 16)}
                InputLabelProps={{ shrink: true }}
                error={!_.isEmpty(endDateHelperMessage)}
                helperText={endDateHelperMessage}
              />
            </>
          )}
        </Styled.AccordionDetail>
      </KanbanAccordionCard>
    </Styled.Container>
  );
};

KanbanAccordionCardFilterDateRange.defaultProps = {
  onChangeDateRange: () => undefined,
  maxRange: undefined
};

export default KanbanAccordionCardFilterDateRange;
