import { TranslationVehiclesModuleMessages, VehiclesModuleMessages } from "../interfaces";

export const messages: TranslationVehiclesModuleMessages = {

  /* region Backend messages */

  /* region Vehicle-events messages */
  [VehiclesModuleMessages.updateVehicleEventSuccess]: "Evento modificado con éxito",
  [VehiclesModuleMessages.eventNotFound]: "Evento no registrado en nuestro sistema",
  [VehiclesModuleMessages.updateVehicleEventError]: "Falla al actualizar evento",
  [VehiclesModuleMessages.readVehicleEventSuccess]: "Evento(s) devuelto(s) con éxito",
  [VehiclesModuleMessages.noVehicleEventFound]: "Ningún evento registrado para el viaje en cuestión",
  [VehiclesModuleMessages.readVehicleEventError]: "Falla al buscar evento(s)",
  /* endregion */
  /* region Vehicles-states messages */
  [VehiclesModuleMessages.createVehicleStateSuccess]: "Estado de vehículo registrado con éxito",
  [VehiclesModuleMessages.createVehicleStateError]: "Falla al registrar estado de vehículo",
  [VehiclesModuleMessages.readVehicleStateSuccess]: "Estado(s) de vehículo devuelto(s) con éxito",
  [VehiclesModuleMessages.noVehicleStateFound]: "Ningún estado de vehículo registrado",
  [VehiclesModuleMessages.readVehicleStateError]: "Falla al buscar estado(s) de vehículo(s)",
  [VehiclesModuleMessages.noVehicleStateInTravelFound]: "Ningún estado de vehículo registrado para el viaje en cuestión",
  [VehiclesModuleMessages.getVehiclesAndTravelsError]: "Falla al buscar informaciones de los vehículos y viajes",
  [VehiclesModuleMessages.getVehiclesHardwaresError]: "Falla al buscar informaciones de los hardwares de los vehículos",
  [VehiclesModuleMessages.getVehiclesAlertsError]: "Falla al buscar informaciones de las alertas de los vehículos",
  [VehiclesModuleMessages.getMixerTravelsSpecificInfoError]: "Falla al buscar informaciones específicas de los viajes de hormigonera",
  [VehiclesModuleMessages.getPumpTravelsSpecificInfoError]: "Falla al buscar informaciones específicas de los viajes de bomba",
  [VehiclesModuleMessages.getAggregateTruckTravelsSpecificInfoError]: "Falla al buscar informaciones específicas de los viajes de camión de agregado",
  [VehiclesModuleMessages.noVehicleStateInVehicleFound]: "Ningún estado de vehículo registrado para el vehículo ",
  /* endregion */
  /* region Vehicle-states-types messages */
  [VehiclesModuleMessages.readVehicleStateTypeSuccess]: "Tipo(s) de estados de vehículo devuelto(s) con éxito",
  [VehiclesModuleMessages.noVehicleStateTypeFound]: "Ningún tipo de estado de vehículo registrado",
  [VehiclesModuleMessages.readVehicleStateTypeError]: "Falla al buscar tipo(s) de estado(s) de vehículo(s)",
  /* endregion */
  /* region Vehicle-types messages */
  [VehiclesModuleMessages.readVehicleTypeSuccess]: "Tipo(s) de vehículo(s) devuelto(s) con éxito",
  [VehiclesModuleMessages.noVehicleTypeFound]: "Ningún tipo de vehículo registrado",
  [VehiclesModuleMessages.readVehicleTypeError]: "Falla al buscar tipo(s) de vehículo(s)",
  /* endregion */
  /* region Vehicles messages */
  [VehiclesModuleMessages.createVehicleSuccess]: "Vehículo registrado con éxito",
  [VehiclesModuleMessages.createVehicleError]: "Falla al registrar vehículo",
  [VehiclesModuleMessages.readVehicleSuccess]: "Vehículo(s) devuelto(s) con éxito",
  [VehiclesModuleMessages.noVehicleFound]: "Ningún vehículo encontrado",
  [VehiclesModuleMessages.readVehicleError]: "Falla al buscar vehículo(s)",
  [VehiclesModuleMessages.updateVehicleSuccess]: "Vehículo modificado con éxito",
  [VehiclesModuleMessages.vehicleNotFound]: "Vehículo no registrado en nuestro sistema",
  [VehiclesModuleMessages.updateVehicleError]: "Falla al actualizar vehículo",
  [VehiclesModuleMessages.deleteVehicleSuccess]: "Vehículo excluido con éxito",
  [VehiclesModuleMessages.onlyInactivateVehicleMessage]: "Vehículo con más de un estado no puede ser excluido, solo inactivado",
  [VehiclesModuleMessages.deleteVehicleError]: "Falla al excluir vehículo",
  [VehiclesModuleMessages.code]: "Código",
  [VehiclesModuleMessages.licensePlate]: "Placa",
  [VehiclesModuleMessages.invalidUserOrPassword]: "Usuario o contraseña inválidos",
  [VehiclesModuleMessages.userNotAuthorizedToBlockVehicle]: "Usuario no autorizado a bloquear vehículo",
  [VehiclesModuleMessages.vehicleWithoutHardware]: "Vehículo no posee hardware",
  [VehiclesModuleMessages.vehicleWithoutBlockCapableHardware]: "Vehículo no posee hardware con bloqueo",
  [VehiclesModuleMessages.blockRequestSent]: "Bloqueo solicitado con éxito",
  [VehiclesModuleMessages.unblockRequestSent]: "Desbloqueo solicitado con éxito",
  [VehiclesModuleMessages.blockRequestError]: "Falla al solicitar bloqueo",
  [VehiclesModuleMessages.general]: "General",
  [VehiclesModuleMessages.concreteMixers]: "Hormigoneras",
  [VehiclesModuleMessages.pumpTrucks]: "Bombas",
  [VehiclesModuleMessages.supportVehicles]: "Apoyo",
  [VehiclesModuleMessages.total]: "Total",
  [VehiclesModuleMessages.average]: "Promedio",

  /* region Vehicle types */
  [VehiclesModuleMessages.concreteMixer]: "Hormigonera",
  [VehiclesModuleMessages.concreteMixers]: "Hormigoneras",
  [VehiclesModuleMessages.pumpTruck]: "Camión de bomba",
  [VehiclesModuleMessages.pumpTrucks]: "Camiones de bomba",
  [VehiclesModuleMessages.supportVehicles]: "Vehículos de apoyo",
  [VehiclesModuleMessages.supportVehicle]: "Vehículo de apoyo",
  [VehiclesModuleMessages.loaders]: "Cargadores",
  [VehiclesModuleMessages.loader]: "Cargador",
  [VehiclesModuleMessages.aggregateTrucks]: "Camiones de agregado",
  [VehiclesModuleMessages.aggregateTruck]: "Camión de agregado",
  [VehiclesModuleMessages.siloTrucks]: "Camiones silo",
  [VehiclesModuleMessages.siloTruck]: "Camión silo",
  /* endregion */

  /* endregion */
  /* region Integration messages */
  [VehiclesModuleMessages.locationNotRegistered]: "Ubicación(es) no registrada(s)",
  [VehiclesModuleMessages.driverNotRegistered]: "Conductor(es) no registrado(s)",
  [VehiclesModuleMessages.externalIDAlreadyRegistered]: "ID(s) externo(s) ya registrado(s)",
  [VehiclesModuleMessages.licensePlateAlreadyRegistered]: "Placa(s) ya registrada(s)",
  [VehiclesModuleMessages.codeAlreadyRegistered]: "Código(s) ya registrado(s)",
  [VehiclesModuleMessages.invalidDateFormat]: "Formato de fecha inválido",
  [VehiclesModuleMessages.noDataForThisVehicleOrDay]: "No hay datos para este vehículo y/o día",
  [VehiclesModuleMessages.readVehicleDailySummarySuccess]: "Resumen diario de vehículo(s) devuelto(s) con éxito",
  [VehiclesModuleMessages.vehicleWithoutTracker]: "Vvehículo no tiene un rastreador vinculado",
  /* endregion */

  /* endregion */
  /* region Frontend messages */
  [VehiclesModuleMessages.description]: "Descripción",
  [VehiclesModuleMessages.status]: "Status",
  [VehiclesModuleMessages.odometer]: "Odómetro",
  [VehiclesModuleMessages.odometerInKm]: "Odómetro (km)",
  [VehiclesModuleMessages.hourMeter]: "Horímetro",
  [VehiclesModuleMessages.hourMeterInHours]: "Horímetro (h)",
  [VehiclesModuleMessages.maintenance]: "En mantenimiento",
  [VehiclesModuleMessages.originalAllocation]: "Asignación original",
  [VehiclesModuleMessages.currentAllocation]: "Asignación actual",
  [VehiclesModuleMessages.currentEvent]: "Evento actual",
  [VehiclesModuleMessages.searchingVehicles]: "Buscando vehículos...",
  [VehiclesModuleMessages.vehicleDeleteConfirmation]: "¿Está seguro que desea excluir el vehículo?",
  [VehiclesModuleMessages.vehicleRegistration]: "Registro de vehículo",
  [VehiclesModuleMessages.activeVehicle]: "Vehículo activo",
  [VehiclesModuleMessages.active]: "activo",
  [VehiclesModuleMessages.inactive]: "Inactivo",
  [VehiclesModuleMessages.vehicleUnderMaintenance]: "Vehículo en mantenimiento",
  [VehiclesModuleMessages.vehicleType]: "Tipo de vehículo",
  [VehiclesModuleMessages.maxSpeedAllowed]: "Velocidad máxima permitida",
  [VehiclesModuleMessages.allocationPlant]: "Central de asignación",
  [VehiclesModuleMessages.defaultDriver]: "Conductor padrón",
  [VehiclesModuleMessages.title]: "Vehículos",
  [VehiclesModuleMessages.deleteDialogTitle]: "Confirmación de exclusión",
  [VehiclesModuleMessages.vehicles]: "Vehículos",
  [VehiclesModuleMessages.blocks]: "Bloqueos"
  /* endregion */
};
