/* eslint-disable import/prefer-default-export */
import {
  TranslationYupMessages,
  YupMessages
} from "../interfaces";

export const messages: TranslationYupMessages = {
  [YupMessages.justificationRequired]: "Justification is required",
  [YupMessages.expectedDateRequired]: "Expected date is required",
  [YupMessages.expectedDateGreaterThanCurrentDate]: "Expected date must be greater than current date",
  [YupMessages.originLocationRequired]: "Origin location is required",
  [YupMessages.loadingLocationRequired]: "Loading location is required",
  [YupMessages.unloadingLocationRequired]: "Unloading location is required",
  [YupMessages.vehicleRequired]: "Vehicle is required",
  [YupMessages.productRequired]: "Product is required",
  [YupMessages.serviceOrderTypeRequired]: "Service order type is required",
  [YupMessages.emailRequired]: "E-mail is required",
  [YupMessages.passwordRequired]: "Password is required",
  [YupMessages.passwordMinLength]: "Password must be at least 6 characters",
  [YupMessages.invalidEmail]: "Enter a valid email",
  [YupMessages.passwordsDontMatch]: "Passwords don't match",
  [YupMessages.justificationMinLength15]: "Justification must be at least 15 characters",
  [YupMessages.finishDateGreaterThanStartDate]: "Finish date must be greater than start date",
  [YupMessages.maxDateInterval24h]: "The maximum interval between dates is 24 hours",
  [YupMessages.maxDateInterval7d]: "The maximum interval between dates is 7 days",
  [YupMessages.maxDateInterval30d]: "The maximum interval between dates is 30 days",
  [YupMessages.codeRequired]: "Code is required",
  [YupMessages.incompleteLicensePlate]: "Incomplete license plate",
  [YupMessages.licensePlateRequired]: "License plate is required",
  [YupMessages.typeRequired]: "Type is required",
  [YupMessages.descriptionRequired]: "Description is required",
  [YupMessages.callerIDRequired]: "Caller ID is required",
  [YupMessages.ICCIDRequired]: "ICCID is required",
  [YupMessages.phoneCompanyRequired]: "Phone company is required",
  [YupMessages.purchaseDateRequired]: "Purchase date is required",
  [YupMessages.fieldRequired]: "Field is required",
  [YupMessages.fieldMaxLength30]: "The field must have a maximum of 30 characters",
  [YupMessages.uniqueField]: "Unique field. This value is already in use",
  [YupMessages.largeFileSize]: "File too large. The maximum size is of FILE_SIZE"
};
