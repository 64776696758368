import styled from "styled-components";

export const ContainerMapAddressDataByMarker = styled.div`

  .pac-card {
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    background-color: #fff;
    font-family: Roboto;

    #title {
      color: rgba(0,0,0,0.60);
      font-size: 25px;
      font-weight: 300;
    }
    #type-selector {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 10px;

      .MuiFormGroup-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
    .pac-controls {
      display: inline-block;
      padding: 5px 11px;

      input {
        cursor: pointer;
      }
      label {
        cursor: pointer;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 300;
        margin: 0 15px 0 5px;
      }
    }
    #pac-container {
      display: block;
      z-index: 10000;
      padding: 15px 0 20px 0;

      .MuiTextField-root {
        width: 100%;

        .MuiOutlinedInput-root {
          height: 35px;

          #pac-input {
            background-color: #fff;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 300;
            text-overflow: ellipsis;
            width: 100%;
          }
          #pac-input:focus {
            border-color: #4d90fe;
          }
        }
      }
    }
  }
`;

export const ContainerMapFixedPointCoord = styled.div`

`;

export const ContainerMapAllVehiclesCoords = styled.div`

  @media (min-width: 800px) { // Desktop

    .map {
      height: 70vh;
      overflow: hidden;
    }
    .vehicles {
      height: 62vh;
      overflow-y: scroll;
      overflow-x: hidden;
      display: block;
    }
    .filter {
      .input {
        width: calc(100% - 14px) !important;
        margin-left: 14px;
      }
    }
  }

  .filter {

    .gm-style-iw-d { // Google info window
      padding: 20px !important;
    }
    .input {
      width: 100%;
      margin-bottom: 10px;
    }
    .vehicles {

      .MuiAccordion-root {
        width: 100%;
        box-shadow: none;
        margin-top: 0;

        .MuiAccordionSummary-root {
          min-height: auto;

          .MuiAccordionSummary-content {
            margin: 5px 0 0 0 !important;
          }
        }
        .MuiAccordionDetails-root {
          display: block;
        }
      }
    }
    .vehicle-summary__accordion {
      width: 100%;
      margin-bottom: 0;
    }
  }
`;

export const MarkerInfo = styled.div`
  display: flex;

  &:first-child {
    margin-bottom: 15px;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 5px;
  }
  & .col-left {
    width: 30px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
  & .col-right {
    width: 100%;
    .content {
      .title {}
      .text {}
    }
  }
`;
