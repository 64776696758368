import { CircularProgress, FormControlLabel, FormGroup, Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { HardwareModels } from "@shared/constants/hardware-models.enum";

import { HardwareTypesID } from "@shared/constants/hardware-types.enum";
import { Vehicle } from "@shared/interfaces/vehicle.interface";

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { TextField } from "unform-material-ui";

import { useToast } from "@hooks/useToast";
import getValidationErrors from "@hooks/getValidationErrors";
import ButtonLoading from "@components/Button/ButtonLoading";
import api from "@services/api";
import utils from "@utils/useful-functions";

import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, HardwareModuleMessages } from "@shared/languages/interfaces";
import { Container, ContainerFilters } from "./styles";

interface LogViewerFormData {
  vehicleDescription: string;
  modelDescription: string;
  filterDate: Date;
}

const LogViewer: React.FC = () => {

  const { addToast } = useToast();
  const { t } = useTranslation();

  /* region States */
  const [vehicles, setVehicles] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [logs, setLogs] = useState<any[]>([] as Array<any>);

  const [openVehicleFilter, setOpenVehicleFilter] = useState(false);
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  const loadingVehicleFilter = openVehicleFilter && optionsVehicleFilter.length === 0;

  const [openModelFilter, setOpenModelFilter] = useState(false);
  const optionsModelFilter = [
    HardwareModels["ST 340UR"],
    HardwareModels["ST 310U"],
    HardwareModels["ST 300HD"],
    HardwareModels["ST 310U EVT"],
    HardwareModels["ST 4315U EVT"],
    HardwareModels["ST 4315U"],
    HardwareModels["ST 4305"],
    HardwareModels["ST 8310U EVT"],
    HardwareModels["ST 8310U"],
    HardwareModels["GV350MG"],
    HardwareModels["GV350CEU"]
  ] as string[];
  const [modelValue, setModelValue] = useState(optionsModelFilter[0]);
  const [modelDisable, setModelDisable] = useState(false);

  const [filterText, setFilterText] = useState("");

  const [loadingLogs, setLoadingLogs] = useState(false);

  const [autoReload, setAutoReload] = React.useState(false);
  const toggleChecked = () => setAutoReload((prev) => !prev);

  const [now, setNow] = useState<Date>(new Date());
  /* endregion States */
  /* region Form */
  const formRef = useRef<FormHandles>(null);

  /** Validations form
   */
  const validations = {

    validateForm: async (formData: LogViewerFormData) => {

      try {

        formRef.current?.setErrors({});

        // Define the validation types
        const schema = Yup.object().shape({
          vehicleDescription: Yup.string(),
          modelDescription: Yup.string().required(t(HardwareModuleMessages.logFormModelRequired))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Return parameters to main Component
        readLogs(formData);

      } catch (error: any) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  /* endregion Form */
  /* region Memo */
  // Create component to show logs
  const logTextAreaComponent = useMemo(() => {
    const lines: JSX.Element[] = [];

    // Filter Queried data
    if (logs.length > 0) {
      for (let i = 0; i < logs.length; i++) {
        const log = logs[i];

        if (filterText === "" || log.toLowerCase().search(filterText.toLowerCase()) >= 0) {
          lines.push(<div className="log-text-area__log-text" key={`${log}${i.toString()}`}>{log}</div>);
        }
      }
    }

    return (
      <>
        <div className="log-text-area">
          {lines}
        </div>
      </>
    );
  }, [logs, filterText]);

  // Sort vehicles options after request
  const sortedVehiclesOptions = useMemo(
    () => optionsVehicleFilter.sort((a, b) => (-b.type.description.localeCompare(a.type.description)
      || -b.code.localeCompare(a.code))), [optionsVehicleFilter]
  );
  /* endregion Memo */

  /* region Functions */
  const updateClock = useCallback(() => {
    setTimeout(() => {
      setNow(new Date());
      updateClock();
    }, 1000);
  }, []);

  // Transform vehicle object to description label
  const generateVehicleLabel = (vehicle: Vehicle) => `${vehicle.code} - ${vehicle.description} (${vehicle?.hardwares?.[0]?.dev_id
  ?? t(HardwareModuleMessages.logNoTracker)})`;

  /** Search Logs according the filters
   * @param formData Data of form filter (Send to request)
   */
  const readLogs = useCallback(async (formData: LogViewerFormData) => {

    try {

      let selectedVehicle;

      // Search the selected vehicle
      vehicles.forEach((vehicle) => {
        if (generateVehicleLabel(vehicle) === formData.vehicleDescription) selectedVehicle = vehicle;
      });

      const devId = selectedVehicle?.hardwares?.[0]?.dev_id;

      // Searh params
      const params = {
        filterDate: utils.convertDateToISOWithTimezone(new Date(formData.filterDate)),
        model: formData.modelDescription, // selectedVehicle?.hardwares?.[0]?.model ?? "st340ur",
        ...(devId ? { devId } : {})
      };

      // Get the alerts of selected vehicle
      setLoadingLogs(true);
      setLogs([]);
      const { data } = await api.get("hardwares/logs/get-filtered-by", { params });

      if (data.status === "success") {

        const preparedResult: string[] = [];

        // Filter resulted data to remove blank lines
        if (data.result.length > 0) {
          for (const log of data.result) {
            if (log.trim().length > 0) {
              preparedResult.push(log);
            }
          }
        }

        setLogs(preparedResult);

        // Scroll div to bottom
        const logTextArea = document.querySelector(".log-text-area");

        if (logTextArea) {
          logTextArea.scrollTop = logTextArea?.scrollHeight ?? 0;
        }

      } else {
        addToast({ type: "info", title: "Alerta!", description: data.message });
      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: "Erro", description: "Conexão não estabelecida" });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingLogs(false);
    }

  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [addToast, setLogs, setLoadingLogs, vehicles]);

  const handleFilterTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  }, []);
  /* endregion Function */
  /* region Effects */

  useEffect(() => {
    updateClock();
  }, [updateClock]);

  // Get vehicles data
  useEffect(() => {

    let active = true;

    if (!loadingVehicleFilter) return undefined;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.get("vehicles/read", { params: { idHardwareType: HardwareTypesID.RASTREADOR } });

        if (data.status === "success") {

          setVehicles(data.result);
          if (active) setOptionsVehicleFilter(data.result);

        } else {

          setOpenVehicleFilter(false);
          addToast({ type: "info", title: "Alerta!", description: data.message });
        }

      } catch (error: any) {
        setOpenVehicleFilter(false);
        if (!error.response) addToast({ type: "error", title: "Erro", description: "Conexão não estabelecida" });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    })();

    return () => { active = false; };

  }, [loadingVehicleFilter, addToast]);

  useEffect(() => {
    if (!openVehicleFilter) setOptionsVehicleFilter([]);
  }, [openVehicleFilter]);
  /* endregion Effects */

  return (
    <>
      <Container>
        <div className="container headerList">
          <div className="headerTitle">
            <div className="title">{t(HardwareModuleMessages.logTitle)}</div>
          </div>
        </div>
        {/* Hack to Load Grid Css */}
        <Grid />
        <ContainerFilters>
          <Form className="form" ref={formRef} onSubmit={validations.validateForm}>
            <div className="filterContent">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Autocomplete
                    id="modelFilter"
                    open={openModelFilter}
                    onOpen={() => setOpenModelFilter(true)}
                    onClose={() => setOpenModelFilter(false)}
                    onChange={(evt, value) => setModelValue(value)}
                    value={modelValue}
                    getOptionLabel={(option) => option}
                    options={optionsModelFilter}
                    disableClearable
                    disabled={modelDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="modelDescription"
                        label={t(HardwareModuleMessages.model)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Autocomplete
                    id="vehicleFilter"
                    open={openVehicleFilter}
                    onOpen={() => setOpenVehicleFilter(true)}
                    onClose={() => setOpenVehicleFilter(false)}
                    getOptionSelected={(option, value) => option.description === value.description}
                    getOptionLabel={(option) => generateVehicleLabel(option)}
                    getOptionDisabled={(option) => option.hardwares.length === 0}
                    options={sortedVehiclesOptions}
                    onChange={(evt, value, reason) => {
                      if (reason === "select-option") {
                        const model = value?.hardwares[0]?.model.replace(" ", "");

                        if (model) setModelValue(model);

                        setModelDisable(true);
                      } else if (reason === "clear") {
                        setModelDisable(false);
                      }
                    }}
                    groupBy={(option) => option.type.description}
                    loading={loadingVehicleFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="vehicleDescription"
                        label={t(HardwareModuleMessages.vehicle)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingVehicleFilter ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="filterDate"
                    label={t(HardwareModuleMessages.startDate)}
                    fullWidth
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    inputProps={{
                      // Add 1 day because utc time
                      max: new Date(new Date().getTime() + 86400000).toISOString().split("T")[0],
                      min: "2021-01-01"
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch disabled checked={autoReload} onChange={toggleChecked} />}
                      label={t(HardwareModuleMessages.logAutoRefresh)}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                  <ButtonLoading
                    type="submit"
                    className="button-loading"
                    loading={loadingLogs}
                    loadingContent={t(HardwareModuleMessages.logSearching)}
                  >{t(GlobalMessages.search)}
                  </ButtonLoading>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <TextField
                    id="filter-after-search"
                    name="filter-after-search"
                    label={t(GlobalMessages.filter)}
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={handleFilterTextChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Form>
        </ContainerFilters>
        {logTextAreaComponent}
        <div>{now.toISOString().slice(0, -5).replace("T", " ")}</div>
      </Container>
    </>
  );
};

export default LogViewer;
