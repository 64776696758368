import React from "react";

/* region Libraries */
import { Tab, Tabs } from "@material-ui/core";
/* endregion */
/* region Languages */
import useTranslation from "src/translations/useTranslation";
import { VehiclesModuleMessages } from "@shared/languages/interfaces";
/* endregion */
/* region Components */
import VehicleList from "@pages/Modules/Vehicles/VehicleList";
import BlockList from "@pages/Modules/Vehicles/BlockList";
import Header from "@components/Header";
import TabPanel from "@components/TabPanel";
/* endregion */
/* region Hooks */
import { useSelector } from "react-redux";
import usePersistedState from "@hooks/usePersistedState";
/* endregion */
/* region Store */
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
/* endregion */
/* region Styles */
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import { Container } from "./styles";
/* endregion */

const Vehicles: React.FC = () => {

  /* region States */
  const [selectedTab, setSelectedTab] = React.useState(0);
  /* endregion */
  /* region Hooks */
  const { screen } = useSelector((screen) => screen);
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  const { t } = useTranslation();
  /* endregion */
  /* region Methods */
  const handleChangeTab = (event, newValue) => { setSelectedTab(newValue); };
  /* endregion */

  return (
    <>
      <Header title={t(VehiclesModuleMessages.vehicles)} />
      <Container platform={screen.platform as ScreenPlatform} selectedTab={selectedTab} className="page">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          className="tabContainer"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.colors.primary
            }
          }}
        >
          <Tab label={t(VehiclesModuleMessages.vehicles)} disableRipple />
          <Tab label={t(VehiclesModuleMessages.blocks)} disableRipple />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <VehicleList />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <BlockList />
        </TabPanel>
      </Container>
    </>
  );
};

export default Vehicles;
