import { VehiclesStatesMessages, TranslationVehiclesStatesMessages } from "../interfaces";

export const messages: TranslationVehiclesStatesMessages = {
  [VehiclesStatesMessages.atThePlant]: "Na usina",
  [VehiclesStatesMessages.onTheWay]: "A caminho",
  [VehiclesStatesMessages.atTheConstructionSite]: "Em obra",
  [VehiclesStatesMessages.unloading]: "Descarregando",
  [VehiclesStatesMessages.returning]: "Retornando",
  [VehiclesStatesMessages.atDestination]: "No destino",
  [VehiclesStatesMessages.inTrustedLocation]: "Em local confiável",
  [VehiclesStatesMessages.pumping]: "Bombeando",
  [VehiclesStatesMessages.idle]: "Ociosa"
};
