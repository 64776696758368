import { VehiclesStatesMessages, TranslationVehiclesStatesMessages } from "../interfaces";

export const messages: TranslationVehiclesStatesMessages = {
  [VehiclesStatesMessages.atThePlant]: "At the plant",
  [VehiclesStatesMessages.onTheWay]: "On the way",
  [VehiclesStatesMessages.atTheConstructionSite]: "At construction site",
  [VehiclesStatesMessages.unloading]: "Unloading",
  [VehiclesStatesMessages.returning]: "Returning",
  [VehiclesStatesMessages.atDestination]: "At destination",
  [VehiclesStatesMessages.inTrustedLocation]: "In trusted location",
  [VehiclesStatesMessages.pumping]: "Pumping",
  [VehiclesStatesMessages.idle]: "Idle"
};
