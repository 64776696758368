/* eslint-disable import/prefer-default-export */
import {
  TranslationYupMessages,
  YupMessages
} from "../interfaces";

export const messages: TranslationYupMessages = {
  [YupMessages.justificationRequired]: "Justificación es obligatoria",
  [YupMessages.expectedDateRequired]: "Fecha prevista es obligatoria",
  [YupMessages.expectedDateGreaterThanCurrentDate]: "La fecha prevista debe ser mayor que la fecha actual",
  [YupMessages.originLocationRequired]: "Ubicación de origen es obligatoria",
  [YupMessages.loadingLocationRequired]: "Ubicación de carga es obligatoria",
  [YupMessages.unloadingLocationRequired]: "Ubicación de descarga es obligatoria",
  [YupMessages.vehicleRequired]: "Vehículo es obligatorio",
  [YupMessages.productRequired]: "Producto es obligatorio",
  [YupMessages.serviceOrderTypeRequired]: "Tipo de O.S. es obligatorio",
  [YupMessages.emailRequired]: "E-mail es obligatorio",
  [YupMessages.passwordRequired]: "Contraseña es obligatoria",
  [YupMessages.passwordMinLength]: "La contraseña debe tener al menos 6 caracteres",
  [YupMessages.invalidEmail]: "Ingrese un correo electrónico válido",
  [YupMessages.passwordsDontMatch]: "Las contraseñas no coinciden",
  [YupMessages.justificationMinLength15]: "La justificación debe tener al menos 15 caracteres",
  [YupMessages.finishDateGreaterThanStartDate]: "La fecha final debe ser posterior a la fecha inicial",
  [YupMessages.maxDateInterval24h]: "El intervalo máximo entre las fechas es de 24 horas",
  [YupMessages.maxDateInterval7d]: "El intervalo máximo entre las fechas es de 7 días",
  [YupMessages.maxDateInterval30d]: "El intervalo máximo entre las fechas es de 30 días",
  [YupMessages.codeRequired]: "Código es obligatorio",
  [YupMessages.incompleteLicensePlate]: "Placa incompleta",
  [YupMessages.licensePlateRequired]: "Placa es obligatoria",
  [YupMessages.typeRequired]: "Tipo es obligatorio",
  [YupMessages.descriptionRequired]: "Descripción es obligatoria",
  [YupMessages.callerIDRequired]: "Caller ID es obligatorio",
  [YupMessages.ICCIDRequired]: "ICCID es obligatorio",
  [YupMessages.phoneCompanyRequired]: "La empresa de telefonia es obligatoria",
  [YupMessages.purchaseDateRequired]: "Fecha de compra es obligatoria",
  [YupMessages.fieldRequired]: "Campo obligatorio",
  [YupMessages.fieldMaxLength30]: "El campo debe tener como máximo 30 caracteres",
  [YupMessages.uniqueField]: "Campo único. Este valor ya está en uso",
  [YupMessages.largeFileSize]: "Archivo demasiado grande. El tamaño máximo es de FILE_SIZE"
};
