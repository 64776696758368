import { TranslationVehiclesModuleMessages, VehiclesModuleMessages } from "../interfaces";

export const messages: TranslationVehiclesModuleMessages = {

  /* region Backend messages */

  /* region Vehicle-events messages */
  [VehiclesModuleMessages.updateVehicleEventSuccess]: "Event updated successfully",
  [VehiclesModuleMessages.eventNotFound]: "Event not registered in our system",
  [VehiclesModuleMessages.updateVehicleEventError]: "Failed to update event",
  [VehiclesModuleMessages.readVehicleEventSuccess]: "Event(s) returned successfully",
  [VehiclesModuleMessages.noVehicleEventFound]: "No event registered for the trip in question",
  [VehiclesModuleMessages.readVehicleEventError]: "Failed to search event(s)",
  /* endregion */
  /* region Vehicles-states messages */
  [VehiclesModuleMessages.createVehicleStateSuccess]: "Vehicle state registered successfully",
  [VehiclesModuleMessages.createVehicleStateError]: "Failed to register vehicle state",
  [VehiclesModuleMessages.readVehicleStateSuccess]: "Vehicle state(s) returned successfully",
  [VehiclesModuleMessages.noVehicleStateFound]: "No vehicle state registered",
  [VehiclesModuleMessages.readVehicleStateError]: "Failed to fetch vehicle state(s)",
  [VehiclesModuleMessages.noVehicleStateInTravelFound]: "No vehicle state registered for the trip in question",
  [VehiclesModuleMessages.getVehiclesAndTravelsError]: "Failed to fetch vehicles and travels information",
  [VehiclesModuleMessages.getVehiclesHardwaresError]: "Failed to fetch vehicles hardwares information",
  [VehiclesModuleMessages.getVehiclesAlertsError]: "Failed to fetch vehicles alerts information",
  [VehiclesModuleMessages.getMixerTravelsSpecificInfoError]: "Failed to fetch mixer travels specific information",
  [VehiclesModuleMessages.getPumpTravelsSpecificInfoError]: "Failed to fetch pump travels specific information",
  [VehiclesModuleMessages.getAggregateTruckTravelsSpecificInfoError]: "Failed to fetch aggregate truck travels specific information",
  [VehiclesModuleMessages.noVehicleStateInVehicleFound]: "No vehicle state registered for the vehicle ",
  /* endregion */
  /* region Vehicle-states-types messages */
  [VehiclesModuleMessages.readVehicleStateTypeSuccess]: "Vehicle state type(s) returned successfully",
  [VehiclesModuleMessages.noVehicleStateTypeFound]: "No vehicle state type registered",
  [VehiclesModuleMessages.readVehicleStateTypeError]: "Failed to fetch vehicle state type(s)",
  /* endregion */
  /* region Vehicle-types messages */
  [VehiclesModuleMessages.readVehicleTypeSuccess]: "Vehicle type(s) returned successfully",
  [VehiclesModuleMessages.noVehicleTypeFound]: "No vehicle type registered",
  [VehiclesModuleMessages.readVehicleTypeError]: "Failed to fetch vehicle type(s)",
  /* endregion */
  /* region Vehicles messages */
  [VehiclesModuleMessages.createVehicleSuccess]: "Vehicle registered successfully",
  [VehiclesModuleMessages.createVehicleError]: "Failed to register vehicle",
  [VehiclesModuleMessages.readVehicleSuccess]: "Vehicle(s) returned successfully",
  [VehiclesModuleMessages.noVehicleFound]: "No vehicle found",
  [VehiclesModuleMessages.readVehicleError]: "Failed to fetch vehicle(s)",
  [VehiclesModuleMessages.updateVehicleSuccess]: "Vehicle updated successfully",
  [VehiclesModuleMessages.vehicleNotFound]: "Vehicle not registered in our system",
  [VehiclesModuleMessages.updateVehicleError]: "Failed to update vehicle",
  [VehiclesModuleMessages.deleteVehicleSuccess]: "Vehicle deleted successfully",
  [VehiclesModuleMessages.onlyInactivateVehicleMessage]: "Vehicle with more than one state cannot be deleted, only inactivated",
  [VehiclesModuleMessages.deleteVehicleError]: "Failed to delete vehicle",
  [VehiclesModuleMessages.code]: "Code",
  [VehiclesModuleMessages.licensePlate]: "License plate",
  [VehiclesModuleMessages.invalidUserOrPassword]: "Invalid user or password",
  [VehiclesModuleMessages.userNotAuthorizedToBlockVehicle]: "User not authorized to block vehicle",
  [VehiclesModuleMessages.vehicleWithoutHardware]: "Vehicle does not have hardware",
  [VehiclesModuleMessages.vehicleWithoutBlockCapableHardware]: "Vehicle does not have hardware capable of blocking",
  [VehiclesModuleMessages.blockRequestSent]: "Block requested successfully",
  [VehiclesModuleMessages.unblockRequestSent]: "Unblock requested successfully",
  [VehiclesModuleMessages.blockRequestError]: "Failed to request block/unblock",
  [VehiclesModuleMessages.general]: "General",
  [VehiclesModuleMessages.concreteMixers]: "Concrete mixers",
  [VehiclesModuleMessages.pumpTrucks]: "Pumps",
  [VehiclesModuleMessages.supportVehicles]: "Support",
  [VehiclesModuleMessages.total]: "Total",
  [VehiclesModuleMessages.average]: "Average",

  /* region Vehicle types */
  [VehiclesModuleMessages.concreteMixer]: "Concrete mixer",
  [VehiclesModuleMessages.concreteMixers]: "Concrete mixers",
  [VehiclesModuleMessages.pumpTruck]: "Pump truck",
  [VehiclesModuleMessages.pumpTrucks]: "Pump trucks",
  [VehiclesModuleMessages.supportVehicle]: "Support vehicle",
  [VehiclesModuleMessages.supportVehicles]: "Support vehicles",
  [VehiclesModuleMessages.loaders]: "Loaders",
  [VehiclesModuleMessages.loader]: "Loader",
  [VehiclesModuleMessages.aggregateTrucks]: "Aggregate trucks",
  [VehiclesModuleMessages.aggregateTruck]: "Aggregate truck",
  [VehiclesModuleMessages.siloTrucks]: "Silo trucks",
  [VehiclesModuleMessages.siloTruck]: "Silo truck",
  /* endregion */

  /* endregion */
  /* region Integration messages */
  [VehiclesModuleMessages.locationNotRegistered]: "Location(s) not registered",
  [VehiclesModuleMessages.driverNotRegistered]: "Driver(s) not registered",
  [VehiclesModuleMessages.externalIDAlreadyRegistered]: "External ID(s) already registered",
  [VehiclesModuleMessages.licensePlateAlreadyRegistered]: "License plate(s) already registered",
  [VehiclesModuleMessages.codeAlreadyRegistered]: "Code(s) already registered",
  [VehiclesModuleMessages.invalidDateFormat]: "Invalid date format",
  [VehiclesModuleMessages.noDataForThisVehicleOrDay]: "No data for this vehicle and/or day",
  [VehiclesModuleMessages.readVehicleDailySummarySuccess]: "Vehicle(s) daily summary returned successfully",
  [VehiclesModuleMessages.vehicleWithoutTracker]: "Vehicle doesn't have a linked tracker",
  /* endregion */

  /* endregion */
  /* region Frontend messages */
  [VehiclesModuleMessages.description]: "Description",
  [VehiclesModuleMessages.status]: "Status",
  [VehiclesModuleMessages.odometer]: "Odometer",
  [VehiclesModuleMessages.odometerInKm]: "Odometer (km)",
  [VehiclesModuleMessages.hourMeter]: "Hour meter",
  [VehiclesModuleMessages.hourMeterInHours]: "Hour meter (h)",
  [VehiclesModuleMessages.maintenance]: "Maintenance",
  [VehiclesModuleMessages.originalAllocation]: "Original allocation",
  [VehiclesModuleMessages.currentAllocation]: "Current allocation",
  [VehiclesModuleMessages.currentEvent]: "Current event",
  [VehiclesModuleMessages.searchingVehicles]: "Searching vehicles...",
  [VehiclesModuleMessages.vehicleDeleteConfirmation]: "Are you sure you want to delete the vehicle",
  [VehiclesModuleMessages.vehicleRegistration]: "Vehicle registration",
  [VehiclesModuleMessages.activeVehicle]: "Active vehicle",
  [VehiclesModuleMessages.active]: "Active",
  [VehiclesModuleMessages.inactive]: "Inactive",
  [VehiclesModuleMessages.vehicleUnderMaintenance]: "Vehicle under maintenance",
  [VehiclesModuleMessages.vehicleType]: "Vehicle type",
  [VehiclesModuleMessages.maxSpeedAllowed]: "Max speed allowed",
  [VehiclesModuleMessages.allocationPlant]: "Allocation plant",
  [VehiclesModuleMessages.defaultDriver]: "Default driver",
  [VehiclesModuleMessages.title]: "Vehicles",
  [VehiclesModuleMessages.deleteDialogTitle]: "Delete confirmation",
  [VehiclesModuleMessages.vehicles]: "Vehicles",
  [VehiclesModuleMessages.blocks]: "Blocks"
  /* endregion */
};
