/* eslint-disable camelcase */
/* eslint-disable max-classes-per-file */

import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";
import { Alert } from "./reg_alerts.entity";
import { Driver } from "./reg_drivers.entity";
import { Event } from "./reg_events.entity";
import { Hardware } from "./reg_hardwares.entity";
import { Location } from "./reg_locations.entity";
import { Travel } from "./reg_travels.entity";
import { VehicleState } from "./reg_vehicles_states.entity";
import { TpeVehicle } from "./tpe_vehicles_types.entity";
import { VehicleUsageType } from "../types/vehicle_usage_type.enum";
import { VehicleCurrentInfo } from "./reg_vehicle_current_infos.entity";
import { HardwareInstallationHistory } from "./hty_hardware_installations.entity";
import { User } from "./reg_users.entity";

@Entity("reg_vehicles")
export class Vehicle {

  @PrimaryGeneratedColumn("uuid")
    id_vehicle?: string;

  @Column({ type: "varchar", nullable: true, unique: true })
    external_id?: string;

  @Column({ type: "varchar", nullable: false, unique: true })
    code?: string;

  @Column({ type: "varchar", nullable: false, length: 50, unique: true })
    license_plate?: string;

  @Column({ type: "varchar", nullable: false })
    description?: string;

  @Column({ type: "boolean", nullable: false, default: true })
    active?: boolean;

  @Column({ type: "boolean", nullable: false, default: false })
    maintenance?: boolean;

  @Column({ type: "boolean", nullable: true })
    engine?: boolean;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @ManyToOne(() => TpeVehicle, (type) => type.vehicles, { nullable: false, onUpdate: "CASCADE" })
  @JoinColumn({ name: "id_vehicle_type_fk" })
    type?: TpeVehicle;

  @ManyToOne(() => Driver, (driver) => driver.vehicles, { nullable: true })
  @JoinColumn({ name: "id_driver_fk" })
    driver?: Driver;

  @ManyToOne(() => Location, (location) => location.vehicles, { nullable: true })
  @JoinColumn({ name: "id_location_fk" })
    location?: Location;

  @ManyToOne(() => Location, (current_location) => current_location.vehicles_at, { nullable: true })
  @JoinColumn({ name: "id_current_location_fk" })
    current_location?: Location;

  @OneToMany(() => Travel, (travels) => travels.vehicle || travels.triggered_vehicle)
    travels?: Travel[];

  @OneToMany(() => Hardware, (hardwares) => hardwares.vehicle)
    hardwares?: Hardware[];

  @OneToMany(() => VehicleState, (states) => states.vehicle)
    states?: VehicleState[];

  @OneToMany(() => Event, (events) => events.vehicle)
    events?: Event[];

  @OneToMany(() => Alert, (alerts) => alerts.vehicle)
    alerts?: Alert[];

  @OneToMany(() => HardwareInstallationHistory, (hardwareInstallationHistory) => hardwareInstallationHistory.vehicle)
    hardwareInstallationHistory?: HardwareInstallationHistory[];

  @OneToOne(() => VehicleCurrentInfo, (vehicle_current_info) => vehicle_current_info.vehicle, { nullable: true })
  @JoinColumn({ name: "id_vehicle_current_info_fk" })
    current_info?: VehicleCurrentInfo;

  @ManyToOne(() => Travel, (current_travel) => current_travel.vehicles_at, { nullable: true })
  @JoinColumn({ name: "id_current_travel_fk" })
    current_travel?: Travel;

  @OneToOne(() => VehicleState, (current_state) => current_state.vehicle, { nullable: true })
  @JoinColumn({ name: "id_current_state_fk" })
    current_state?: VehicleState;

  @ManyToOne(() => Event, (current_event) => current_event.vehicles_at, { nullable: true })
  @JoinColumn({ name: "id_current_event_fk" })
    current_event?: Event;

  @ManyToOne(() => Event, (last_event) => last_event.vehicles_at_last, { nullable: true })
  @JoinColumn({ name: "id_last_event_fk" })
    last_event?: Event;

  @ManyToMany(() => User, (user) => user.restricted_vehicles, { nullable: true })
  @JoinTable({
    name: "rel_users_vehicles_restrictions",
    joinColumn: {
      name: "id_vehicle_fk",
      referencedColumnName: "id_vehicle"
    },
    inverseJoinColumn: {
      name: "id_user_fk",
      referencedColumnName: "id_user"
    }
  })
    restricted_users?: User[];

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    current_speed?: number;

  @Column({
    type: "numeric",
    nullable: true,
    default: 80,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    maximum_speed_allowed?: number;

  @Column({
    type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    current_odometer?: number;

  @Column({ type: "boolean", nullable: false, default: false })
    blocked?: boolean;

  @Column({ type: "boolean", nullable: false, default: false })
    driver_ibutton_verified?: boolean;

  @Column({ type: "timestamp", nullable: true })
    last_ibutton_verification_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    last_ignition_date?: Date;

  @Column({
    type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    current_hour_meter?: number;

  @Column({ type: "enum", enum: VehicleUsageType, nullable: true })
    current_use?: VehicleUsageType;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    total_water_added?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    current_mixer_rpm?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    current_mixer_inclination?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    current_mixer_pressure?: number;

  GetLabelName?(): string {
    return `${this.code} - ${this.description}`;
  }

}

// Class to use in the API (Public Data - Vehicle READ)
export class VehicleWithTrackerAndSensor extends Vehicle {

  tracker?: Hardware;

  sensor?: Hardware;

}

export class VehicleErrors {

  static readonly UNIQUE_CONSTRAINT = "3.10.1 (TCON33313031)";

  static readonly VEHICLE_TYPE_NOT_FOUND = "3.10.2 (TCON33313032)";

  static readonly VEHICLE_IN_USE = "3.10.3 (TCON33313033)";

}
