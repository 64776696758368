/* eslint-disable import/prefer-default-export */
import {
  MenuMessages,
  TranslationMenuMessages
} from "../interfaces";

export const messages: TranslationMenuMessages = {
  [MenuMessages.production]: "Production",
  [MenuMessages.pumps]: "Pumps",
  [MenuMessages.supportVehicles]: "Support vehicles",
  [MenuMessages.trucks]: "Trucks",
  [MenuMessages.dashboards]: "Dashboards",
  [MenuMessages.management]: "Management",
  [MenuMessages.users]: "Users",
  [MenuMessages.modules]: "Modules",
  [MenuMessages.drivers]: "Drivers",
  [MenuMessages.vehicles]: "Vehicles",
  [MenuMessages.locations]: "Locations",
  [MenuMessages.hardwares]: "Hardwares",
  [MenuMessages.SIMCards]: "SIM Cards",
  [MenuMessages.reports]: "Reports",
  [MenuMessages.scheduledReports]: "Scheduled reports",
  [MenuMessages.travelHistory]: "Travel history",
  [MenuMessages.alertHistory]: "Alert history",
  [MenuMessages.coordinateAnalysis]: "Coordinate analysis",
  [MenuMessages.vehicleHistory]: "Vehicle history",
  [MenuMessages.others]: "Others",
  [MenuMessages.contactUs]: "Contact us",
  [MenuMessages.settings]: "Settings",
  [MenuMessages.installApp]: "Install app",
  [MenuMessages.exit]: "Exit",
  [MenuMessages.generalLocation]: "General location",
  [MenuMessages.vehiclesUnderMaintenance]: "Vehicles under maintenance",
  [MenuMessages.alerts]: "Alerts",
  [MenuMessages.speedingAlerts]: "Speeding alerts",
  [MenuMessages.concreteExpiration]: "Concrete expiration",
  [MenuMessages.improperDischarges]: "Improper discharges",
  [MenuMessages.vehiclesInTrustedLocations]: "Vehicles in trusted locations",
  [MenuMessages.blockedVehicles]: "Blocked vehicles",
  [MenuMessages.regionals]: "Regionals",
  [MenuMessages.help]: "Help",
  [MenuMessages.groups]: "Groups",
  [MenuMessages.pumpProgramming]: "Pump programming",
  [MenuMessages.zones]: "Restriction Zones"
};
