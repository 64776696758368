/* eslint-disable camelcase */
/* eslint-disable no-shadow */

import { Location } from "@shared/interfaces/location.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { Regional } from "@shared/interfaces/regional.interface";
import { Group } from "@shared/interfaces/group.interface";

export interface LocationWithClient extends Location {
  client: string;
}

// Action types
export enum FiltersDashboardTypes {
  SET_FILTERS_DASHBOARD = "@filtersDashboard/SET_FILTERS_DASHBOARD",
}

// Data Types
interface FilterVehiclesTypes { betoneiras: boolean; bombas: boolean; veiculoApoio: boolean, paCarregadeira: boolean }

// State type
export interface FiltersDashboardState {
  active: boolean,
  vehiclesTypes: FilterVehiclesTypes,
  vehicles: Vehicle[],
  constructions: LocationWithClient[],
  plants: Location[],
  regionals: Regional[],
  groups: Group[],
}
